import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { ProductSubscriptionListingFragment } from "../../../generated/gateway-client";
import { PriceFormat } from "../../PriceFormat";
import { ProductListing } from "./ProductListing";
import { SubscriptionTag } from "./SubscriptionTag";

interface Props {
  product: ProductSubscriptionListingFragment;
  className?: string;
  metric?: {
    ns: string;
    nx: number;
  };
}

export const ListingSubscription: React.FC<Props> = ({ metric, product, className = "" }) => {
  const { t } = useTranslation();

  if (product.__typename !== "SubscriptionProduct") {
    return null;
  }

  const metricExt = metric == null ? "" : `?ns=${metric.ns}&nx=${metric.nx}`;

  return (
    <ProductListing
      className={className}
      name={product.name}
      image={product.image}
      available={product.available}
      outOfStock={product.outOfStock}
      description={product.description}
      suffix={<SubscriptionTag top="1px" left="1px" />}
      url={{
        as: `/store/product/${product.uuid}${metricExt}`,
        href: "/store/product/[uuid]",
      }}
    >
      <div className="price mt-auto">
        <PriceFormat price={product.unitPrice} freeTxt={t("main.free")} billingCycle={product.billingCycle} />
        {product.availability.limitedAvailability ? <span>{" "}({t("main.restrictedAvailability")})</span> : null}
      </div>
    </ProductListing>
  );
}
